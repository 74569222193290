import { isDefined } from "@clipboard-health/util-ts";
import { useGetSolveUnpaidBreaksStage } from "@src/appV2/Facilities/MandatoryBreaks/api/useGetSolveUnpaidBreaksStage";
import { useAppInfo } from "@src/appV2/lib";
import { isSolveUnpaidBreaksFeatureEnabled } from "@src/appV2/Shifts/utils/isSolveUnpaidBreaksFeatureEnabled";
import { Shift } from "@src/lib/interface/src";

export function useIsSolveUnpaidBreaksEnabled(shift: Shift): boolean {
  const { data: solveUnpaidBreaksRolloutStageConfigs } = useGetSolveUnpaidBreaksStage(
    shift.facilityId?.toString() ?? "",
    { enabled: isDefined(shift.facilityId) }
  );
  const { appInfo } = useAppInfo();
  const solveUnpaidBreaksRolloutStageWithTargetingConfig =
    solveUnpaidBreaksRolloutStageConfigs?.solveUnpaidBreaksStageWithTargeting;

  const isNewTimeSheetEnabled = shift?.facility?.featureSettings?.displayTimeSheetSummary ?? true;
  const isDigitalSignatureEnabled = shift?.facility?.featureSettings?.digitalSignature ?? true;
  const isSignatureSubmission = isNewTimeSheetEnabled && isDigitalSignatureEnabled;

  if (!isDefined(solveUnpaidBreaksRolloutStageWithTargetingConfig)) {
    // TODO(clipboardhealth.atlassian.net/browse/TX-761): Replace this with true once the flag is removed
    return false;
  }

  return (
    isSignatureSubmission &&
    isSolveUnpaidBreaksFeatureEnabled({
      solveUnpaidBreaksRolloutStageWithTargetingConfig,
      appInfo,
    })
  );
}
