import { get } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { z } from "zod";

import { syncActionsWhenNetworkIsReachable } from "../store/ongoingShifts";

const getHealthCheckSchema = z.object({
  uptime: z.number(),
  message: z.string(),
  timestamp: z.number(),
});

const HEALTH_CHECK_URL = `${environmentConfig.REACT_APP_BASE_API_URL}/healthCheck`;
export async function getNetworkHealthCheck() {
  return await get({
    url: HEALTH_CHECK_URL,
    responseSchema: getHealthCheckSchema,
  });
}

export function useNetworkHealthCheck() {
  const dispatch = useDispatch();
  useQuery({
    queryKey: [HEALTH_CHECK_URL],
    queryFn: getNetworkHealthCheck,
    refetchInterval: 5000,
    onSuccess: () => {
      dispatch(syncActionsWhenNetworkIsReachable());
    },
  });
}
