import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useLocation } from "react-router-dom";

import { PageMetaMap } from "../constants";

const HelpDetailsPage = () => {
  //Used to open terms of policy by default
  const defaultMeta = "terms";

  const location: any = useLocation();
  const [title, url] = [
    location.state?.title || PageMetaMap.get(defaultMeta)?.title,
    location.state?.url || PageMetaMap.get(defaultMeta)?.url,
  ];

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle style={{ marginLeft: "8px" }}>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <iframe title="CBH Support" width="100%" height="100%" allowFullScreen src={url} />
      </IonContent>
    </IonPage>
  );
};

export default HelpDetailsPage;
