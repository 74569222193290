/* eslint-disable no-shadow */
export enum Pages {
  TERMS = "terms",
}

export const PageMetaMap = new Map([
  [
    "terms",
    {
      title: "Terms of Service",
      url: "https://drive.google.com/file/d/1iF4XBMxsJDwqg8-Cl7Xm_28idBqQqM3G/preview",
    },
  ],
]);
