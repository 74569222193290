import * as braze from "@braze/web-sdk";
import { IonButton, IonButtons, IonIcon } from "@ionic/react";
import { CustomBadge } from "@src/app/chat/customBadge";
import { logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants/userEvents";
import { ActionType } from "@store/session";
import { Store } from "@store/store.model";
import { notificationsOutline } from "ionicons/icons";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { filterContentCard } from "./notificationCenter";
import { TabRouterPath } from "../routing/constant/tabRoute";

const timeIntervalForPolling = 60 * 1000; // 1 minute

interface IOpenNotificationCenterIcon {
  logIconViewedEvent?: boolean;
}

export const OpenNotificationCenterIcon: FC<IOpenNotificationCenterIcon> = ({
  logIconViewedEvent,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [unreadNotificationCount, setUnreadNotificationCount] = useState<number>(0);

  const { loggedNCIconViewedEvent } = useSelector((state: Store) => state.session);

  const dispatch = useDispatch();

  const componentIsVisible = pathname.includes("openShifts") || pathname.includes("myShifts");
  const getUnreadNotificationCount = useCallback(
    () =>
      braze
        .getCachedContentCards()
        .cards.filter(filterContentCard)
        .filter((card) => !card.viewed).length,
    []
  );

  const brazePoll = useCallback(() => {
    braze.requestContentCardsRefresh(() => {
      const unreadCount = getUnreadNotificationCount();
      setUnreadNotificationCount(unreadCount);
      if (logIconViewedEvent && !loggedNCIconViewedEvent) {
        const trackProperties = {
          context: pathname.includes("openShifts") ? "openShifts" : "myShifts",
          n_unread_messages: unreadCount,
          has_unread_badge: unreadCount > 0,
        };
        logEvent(USER_EVENTS.NOTIFICATION_CENTER_ICON_VIEWED, trackProperties);
        dispatch({
          type: ActionType.LOG_NC_ICON_VIEWED_EVENT,
          data: { loggedNCIconViewedEvent: true },
        });
      }
    });
  }, [logIconViewedEvent, getUnreadNotificationCount, pathname, dispatch, loggedNCIconViewedEvent]);

  useEffect(() => {
    if (!componentIsVisible) {
      return;
    }
    brazePoll();
    const timer = setInterval(brazePoll, timeIntervalForPolling);
    return () => clearInterval(timer);
  }, [brazePoll, componentIsVisible]);

  if (!componentIsVisible) {
    return null;
  }

  const openNotificationCenter = () => {
    const trackProperties = {
      context: pathname.includes("openShifts") ? "openShifts" : "myShifts",
      n_unread_messages: unreadNotificationCount,
      has_unread_badge: unreadNotificationCount > 0,
    };
    logEvent(USER_EVENTS.NOTIFICATION_CENTER_VIEWED, trackProperties);
    history.push(TabRouterPath.NOTIFICATION_CENTER);
  };

  return (
    <>
      <IonButtons slot="start">
        <IonButton onClick={openNotificationCenter} data-testid="notification-center-icon">
          <IonIcon icon={notificationsOutline} style={{ color: "white" }} />
          {unreadNotificationCount > 0 && (
            <div data-testid="notification-center-unread-count" style={CustomBadge}>
              {unreadNotificationCount}
            </div>
          )}
        </IonButton>
      </IonButtons>
    </>
  );
};
