import * as braze from "@braze/web-sdk";
import { RefresherEventDetail } from "@ionic/core";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { FC, useCallback, useEffect, useRef } from "react";

import { TabRouterPath } from "../routing/constant/tabRoute";

import "./style.scss";

const contentCardKeyValues = [
  {
    key: "type",
    value: "NOTIFICATION_CENTER",
  },
];

export const filterContentCard = (card: braze.Card) =>
  contentCardKeyValues.every(({ key, value }) => card?.extras?.[key] === value);

export const NotificationCenter: FC = () => {
  const contentCardFeedRef = useRef<HTMLDivElement>(null);

  const filterContentCardsByKeyValuePairs = useCallback(
    (cards: braze.Card[] = []) => cards.filter(filterContentCard),
    []
  );

  useEffect(() => {
    let parentNodeForBraze;
    if (contentCardFeedRef?.current) {
      parentNodeForBraze = contentCardFeedRef?.current;
      braze.requestContentCardsRefresh(() => {
        braze.showContentCards(contentCardFeedRef?.current, filterContentCardsByKeyValuePairs);
      });
    }
    return () => {
      if (parentNodeForBraze) {
        // This is needed so that Braze does not mark notification as read
        // in the background
        braze.hideContentCards(parentNodeForBraze.current);
      }
    };
  }, [filterContentCardsByKeyValuePairs]);

  const onRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    braze.requestContentCardsRefresh(() => {
      event.detail.complete();
    });
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar className="relative" mode="ios">
          <IonButtons align-center>
            <IonBackButton text="" defaultHref={TabRouterPath.OPEN_SHIFTS} mode="ios" />
          </IonButtons>
          <IonTitle
            className="negotiation-center-header-title"
            data-testid="notification-center-title"
          >
            Notifications
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <div
          id="content-cards-container"
          data-testid="content-cards-container"
          ref={contentCardFeedRef}
        ></div>
      </IonContent>
    </IonPage>
  );
};
